<template>
    <!-- Table Container Card -->
    <b-card no-body>
        <div class="m-2">
            <customer-list-add-new
                :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
                @refetch-data="refetchData"
            />
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>Entries</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                    <b-button variant="primary" @click="isAddNewUserSidebarActive = true">Add Customer</b-button>
                    <!-- <b-button
            variant="primary"
            :to="{ name: 'ecommerce-order-add'}"
          >
            Add Order
          </b-button> -->
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <v-select
                            v-model="isPayingFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="isPayingOptions"
                            :reduce="val => val.value"
                            class="invoice-filter-select"
                            placeholder="Paying Customer"
                        >
                            <template #selected-option="{ label }">
                                <p>
                                    {{ label }}
                                </p>
                            </template>
                        </v-select>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-table
            ref="refCustomerListTable"
            :items="fetchCustomers"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
        >
            <!-- Column: Id -->
            <template #cell(id)="data">
                <span :id="`id-customer-${data.item.id}`">#{{ data.item.originId }}</span>
                <b-tooltip :target="`id-customer-${data.item.id}`" triggers="hover">
                    <a :href="data.item.shopUrl" class="text-white"
                        ><b>{{ data.item.shopName }}</b></a
                    >
                </b-tooltip>
            </template>

            <!-- Column: Name -->
            <template #cell(fullName)="data">
                <b-link
                    :to="{
                        name: 'ecommerce-customer-view',
                        params: { id: data.item.id },
                    }"
                >
                    {{ data.item.fullName }}
                </b-link>
            </template>

            <!-- Column: Email -->
            <template #cell(email)="data">
                <span>{{ data.item.email }}</span>
            </template>

            <!-- Column: Registration Date -->
            <template #cell(originCreateDate)="data">
                <span class="text-nowrap text-capitalize">
                    {{ data.item.originCreateDate | formatDate }}
                </span>
            </template>

            <!-- Column: Is Paying Customer -->
            <template #cell(isPayingCustomer)="data">
                <template>
                    <b-badge
                        pill
                        :variant="`light-${resolveIsPayingCustomer(data.value).variant}`"
                        class="text-capitalize"
                    >
                        {{ resolveIsPayingCustomer(data.value).label }}
                    </b-badge>
                </template>
            </template>
        </b-table>
        <b-row v-if="totalCustomers === 0" class="mx-2 d-flex justify-content-center"
            ><span class="font-weight-bold">No Records Found</span></b-row
        >
        <div v-if="tableLoading" class="text-center my-1">
            <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
        </div>
        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                    >
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalCustomers"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BTable,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted, ref } from "@vue/composition-api";
import store from "@/store";
import useCustomerList from "./useCustomerList";
import CustomerListAddNew from "./CustomerListAddNew";
import customerStoreModule from "../customerStoreModule";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BLink,
        BSpinner,
        BTable,
        BBadge,
        // BDropdown,
        // BDropdownItem,
        BPagination,
        BTooltip,
        CustomerListAddNew,
        vSelect,
    },
    setup() {
        const ECOMMERCE_CUSTOMER_STORE_MODULE_NAME = "ecommerce-customer";

        // Register module
        if (!store.hasModule(ECOMMERCE_CUSTOMER_STORE_MODULE_NAME)) {
            store.registerModule(ECOMMERCE_CUSTOMER_STORE_MODULE_NAME, customerStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ECOMMERCE_CUSTOMER_STORE_MODULE_NAME)) {
                store.unregisterModule(ECOMMERCE_CUSTOMER_STORE_MODULE_NAME);
            }
        });

        const isAddNewUserSidebarActive = ref(false);

        const isPayingOptions = [
            {
                label: "Yes",
                value: 1,
            },
            {
                label: "No",
                value: 0,
            },
        ];

        const {
            fetchCustomers,
            tableColumns,
            perPage,
            currentPage,
            totalCustomers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCustomerListTable,
            isPayingFilter,
            refetchData,
            tableLoading,
            resolveIsPayingCustomer,
        } = useCustomerList();

        return {
            fetchCustomers,
            tableColumns,
            tableLoading,
            perPage,
            currentPage,
            totalCustomers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCustomerListTable,
            isPayingFilter,
            refetchData,
            isPayingOptions,
            isAddNewUserSidebarActive,
            avatarText,
            resolveIsPayingCustomer,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
