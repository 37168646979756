import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default function useCustomerList() {
    // Use toast
    const toast = useToast();

    const refCustomerListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        {
            key: "id",
            label: "#",
            sortable: false,
        },
        {
            key: "fullName",
            label: "Full Name",
            sortable: false,
        },
        {
            key: "email",
            label: "Email",
            sortable: false,
        },
        {
            key: "originCreateDate",
            label: "Registration Date",
            sortable: true,
        },
        {
            key: "isPayingCustomer",
            sortable: false,
        },
    ];
    const perPage = ref(25);
    const totalCustomers = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isPayingFilter = ref(null);
    const tableLoading = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0;

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCustomers.value,
        };
    });

    const refetchData = () => {
        refCustomerListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, isPayingFilter], () => {
        refetchData();
    });

    const fetchCustomers = (ctx, callback) => {
        store
            .dispatch("ecommerce-customer/fetchCustomers", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                isPayingCustomer: isPayingFilter.value,
            })
            .then(response => {
                const { customers, total } = response.data;
                tableLoading.value = false;
                callback(customers);
                totalCustomers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching customers' list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveIsPayingCustomer = isPayingCustomer => {
        if (isPayingCustomer) {
            return {
                label: "Yes",
                variant: "success",
            };
        }

        return {
            label: "No",
            variant: "danger",
        };
    };

    return {
        fetchCustomers,
        tableColumns,
        perPage,
        currentPage,
        totalCustomers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCustomerListTable,

        isPayingFilter,
        resolveIsPayingCustomer,
        tableLoading,
        refetchData,
    };
}
