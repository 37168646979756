<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Add New Customer</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- First Name -->
                    <validation-provider #default="validationContext" name="First Name" rules="required">
                        <b-form-group label="First Name" label-for="first-name">
                            <b-form-input
                                id="first-name"
                                v-model="userData.first_name"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="John"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Last Name" rules="required">
                        <b-form-group label="Last Name" label-for="last-name">
                            <b-form-input
                                id="lastt-name"
                                v-model="userData.last_name"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Doe"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider #default="validationContext" name="Username" rules="required|alpha-num">
                        <b-form-group label="Username" label-for="username">
                            <b-form-input
                                id="username"
                                v-model="userData.username"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="johndoe"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email -->
                    <validation-provider #default="validationContext" name="Email" rules="required|email">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input
                                id="email"
                                v-model="userData.email"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="john@doe.com"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- <validation-provider #default="validationContext" name="Email" rules="required|num">
                        <b-form-group label="Shop ID" label-for="shop-id">
                            <b-form-input
                                id="shop-id"
                                v-model="userData.shopId"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="136"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> -->

                    <!-- SHOP -->
                    <b-form-group label="Shop" label-for="shop">
                        <v-select
                            id="shop"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="shopOptions"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Start typing..."
                            @search="searchShops"
                            @input="val => updateShopId(val)"
                        >
                            <!--          v-model="blankOrderData.order.shopId"-->
                            <!--@input="val => updateItemForm(index, val)"-->
                            <template #option="{ name }">
                                <span>
                                    {{ name }}
                                </span>
                            </template>
                        </v-select>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Add
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { debounce } from "debounce";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onUnmounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewUserSidebarActive",
        event: "update:is-add-new-user-sidebar-active",
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        // roleOptions: {
        //     type: Array,
        //     required: true,
        // },
        // planOptions: {
        //     type: Array,
        //     required: true,
        // },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            // countries,
        };
    },
    setup(props, { emit }) {
        const INVOICE_APP_STORE_MODULE_NAME = "ecommerce-customer";

        // register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
            store.registerModule(INVOICE_APP_STORE_MODULE_NAME);
        }
        // unregister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            }
        });

        const shopOptions = ref([]);

        const blankUserData = {
            email: "",
            first_name: "",
            last_name: "",
            username: "",
            shopId: null,
        };

        const updateShopId = (shopIdObj, target) => {
            target.value = shopIdObj.id;
        };

        const searchShops = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getShopsWithQuery(search, loading), 500);
            }
        };

        const getShopsWithQuery = (query, loadingFunc) => {
            store
                .dispatch("ecommerce-customer/fetchShops", {
                    query: query,
                })
                .then(res => {
                    shopOptions.value = res.shops;
                })
                .finally(() => {
                    loadingFunc(false);
                });
        };

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
        const resetUserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData));
        };

        const onSubmit = () => {
            store.dispatch("ecommerce-customer/addCustomer", userData.value).then(() => {
                emit("refetch-data");
            });
            emit("update:is-add-new-user-sidebar-active", false);
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetUserData);

        return {
            userData,
            onSubmit,
            shopOptions,
            searchShops,
            getShopsWithQuery,
            refFormObserver,
            getValidationState,
            resetForm,
            updateShopId,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
